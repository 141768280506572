export default [{
        title: "Ana Sayfa",
        route: "home",
        icon: "HomeIcon",
        permission: 'ALL'
    },
    {
        title: "Firmalar",
        route: "company-page",
        icon: "TargetIcon",
        permission: 'SUPERADMIN'
    },
    {
        title: "Kullanıcılar",
        route: "users-page",
        icon: "UsersIcon",
        permission: 'SUPERADMIN'
    },
    {
        title: "Paketler",
        route: "packages-page",
        icon: "PackageIcon",
        permission: 'SUPERADMIN'
    },
    {
        title: "Modüller",
        route: "modules-page",
        icon: "GitBranchIcon",
        permission: 'SUPERADMIN'
    },
    {
        title: "Okullar",
        route: "schools-page",
        icon: "TagIcon",
        permission: 'SUPERADMIN'
    },
    {
        title: "Kullanıcılar",
        route: "company-users-page",
        icon: "UsersIcon",
        permission: 'COMPANYADMIN'
    },
    {
        title: "Şubeler",
        route: "company-branchs-page",
        icon: "LayersIcon",
        permission: 'COMPANYADMIN'
    },
    {
        title: "Senkronizasyon",
        route: "company-hooks-page",
        icon: "LayersIcon",
        permission: 'COMPANYADMIN'
    },
    {
        title: "Sınıflar",
        route: "branch-classes-page",
        icon: "LayersIcon",
        permission: 'BRANCHADMIN'
    },
    {
        title: "Dersler",
        route: "branch-lessons-page",
        icon: "LayersIcon",
        permission: 'BRANCHADMIN'
    },
    {
        title: "Öğretmenler",
        route: "branch-teachers-page",
        icon: "UsersIcon",
        permission: 'BRANCHADMIN'
    },
    {
        title: "Öğrenciler",
        route: "branch-students-page",
        icon: "UsersIcon",
        permission: 'BRANCHADMIN'
    },
    {
        title: "Veliler",
        route: "branch-guardians-page",
        icon: "UsersIcon",
        permission: 'BRANCHADMIN'
    },
    {
        title: "Entegrasyonlar",
        route: "branch-entegration-page",
        icon: "CpuIcon",
        permission: 'BRANCHADMIN'
    },
    {
        title: "Ders Programı",
        route: "branch-syllabus-page",
        icon: "SlidersIcon",
        permission: 'BRANCHADMIN'
    }, {
        title: "Yoklama",
        route: "branch-attendence",
        icon: "UsersIcon",
        permission: 'BRANCHADMIN'
    },
    {
        title: "İstek/Öneri",
        route: "branch-contact-page",
        icon: "AlertTriangleIcon",
        permission: 'BRANCHADMIN'
    },
    {
        title: "Öğrencilerim",
        route: "teacher-students-page",
        icon: "UsersIcon",
        permission: 'TEACHER'
    },
    {
        title: "Derslerim",
        route: "teacher-lessons-page",
        icon: "LayersIcon",
        permission: 'TEACHER'
    },
    {
        title: "Toplu Ev Ödevi Ekle",
        route: "teacher-add-homework-page",
        icon: "BriefcaseIcon",
        permission: 'TEACHER'
    },
    {
        title: "Canlı Dersler",
        route: "teacher-liveLessons-page",
        icon: "AirplayIcon",
        permission: 'TEACHER'
    },
    {
        title: "Rehberlik",
        route: "teacher-interview-page",
        icon: "PocketIcon",
        permission: 'TEACHER'
    },
    {
        title: "Soru-Cevap",
        route: "teacher-reply-page",
        icon: "RefreshCwIcon",
        permission: 'TEACHER'
    },
    {
        title: "Yoklama",
        route: "teacher-attendance-page",
        icon: "UserPlusIcon",
        permission: 'TEACHER'
    },
    {
        title: "Deneme Sınavları",
        route: "exams-page",
        icon: "DatabaseIcon",
        permission: 'ALL'
    },
    {
        title: "Mesajlaşma Grupları",
        route: "teacher-chat-group-page",
        icon: "MailIcon",
        permission: 'TEACHER'
    },
    {
        title: "Optik Tarama",
        route: "teacher-optick-page",
        icon: "CameraIcon",
        permission: 'TEACHER'
    },
    {
        title: "Raporlar",
        route: "report-selection-page",
        icon: "PieChartIcon",
        permission: 'TEACHER'
    },
    {
        title: "Anketler",
        route: "branch-surveys-page",
        icon: "PieChartIcon",
        permission: 'BRANCHADMIN'
    },
    {
        title: "Anket Cevapları",
        route: "teacher-survey-page",
        icon: "PieChartIcon",
        permission: 'BRANCHADMIN'
    },
    {
        title: "Ödev Raportları",
        route: "branch-homework-page",
        icon: "BarChartIcon",
        permission: 'BRANCHADMIN'
    },
];